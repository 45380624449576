// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/css/style.pcss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';

// Barba
import barba from '@barba/core';
import { animate, cubicBezier } from 'popmotion';


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp, defineAsyncComponent } from 'vue';

// Components
import Rot13Email from './components/Rot13Email.vue';
import TickTo from './components/TickTo.vue';
import Accordion from './components/Accordion.vue';
import Cards from './components/Cards.vue';
import CollapseTransition from './components/CollapseTransition.vue';
const Map = defineAsyncComponent(() => import(/* webpackChunkName: "map" */ './components/Map.vue'));

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import {
    Autoplay,
    Pagination,
    Lazy,
    Parallax,
    Scrollbar,
    Mousewheel,
} from 'swiper';

let app;

function init(container = '#app') {
    app = createApp({
        delimiters: [ '${', '}' ],

        components: {
            Swiper,
            SwiperSlide,
        },

        data() {
            return {
                subNavs: {},
                isMenuOpen: false,
                swiperModules: [ Autoplay, Pagination, Lazy, Parallax, Scrollbar, Mousewheel ],
            };
        },

        methods: {
            onToggleMenu() {
                this.isMenuOpen = !this.isMenuOpen;
            },
            onToggleNode(nodeId) {
                this.subNavs[nodeId] = !this.subNavs[nodeId];
            },
            renderBullet(index, className) {
                return `<div class="${className}"><span>${index + 1}</span></div>`;
            },
        },
    });

    app.component('Rot13Email', Rot13Email);
    app.component('TickTo', TickTo);
    app.component('Accordion', Accordion);
    app.component('MapboxMap', Map);
    app.component('Cards', Cards);
    app.component('CollapseTransition', CollapseTransition);

    app.mount(container);
}

let swipeEl;
const easing = cubicBezier(0, 0.2, 1, 0.8);

barba.init({
    prevent: ({ el }) => {
        const href = el.getAttribute('href');

        return /\/contact-us$/i.test(href) || /\/uploads\//.test(href);
    },
    transitions: [ {
        name: 'swipe-transition',
        beforeLeave(data) {
            swipeEl = document.createElement('div');
            swipeEl.className = 'swipe';
            document.body.appendChild(swipeEl);
        },
        leave(data) {
            return new Promise(resolve => {
                animate({
                    from: 0,
                    to: 1,
                    onUpdate: latest => {
                        const eased = easing(latest) * 100;
                        const a = Math.min(100, eased / 50 * 100);
                        const b = Math.max(0, (eased - 50) * 2);

                        swipeEl.style.clipPath = `polygon(100% 0, ${a}% ${b}%, ${b}% ${a}%, 0 100%, 0 0)`;
                    },
                    onComplete: resolve,
                });
            });
        },
        enter(data) {
            return new Promise(resolve => {
                animate({
                    from: 0,
                    to: 1,
                    onUpdate: latest => {
                        const eased = easing(latest) * 100;
                        const a = Math.min(100, eased / 50 * 100);
                        const b = Math.max(0, (eased - 50) * 2);

                        swipeEl.style.clipPath = `polygon(100% 0, 100% 100%, 0 100%, ${b}% ${a}%, ${a}% ${b}%)`;
                    },
                    onComplete: resolve,
                });
            });
        },
        afterEnter(data) {
            swipeEl.remove();
            swipeEl = null;
        },
    } ],
});

barba.hooks.afterLeave(() => {
    app.unmount();
});

barba.hooks.beforeEnter(data => {
    init(data.next.container);
    window.scrollTo(0, 0);
});

init();
