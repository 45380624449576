<template>
    <section>
        <slot :expanded="expanded" :toggle="toggle" />
    </section>
</template>

<script>
    export default {
        data() {
            return {
                expanded: {},
            };
        },

        methods: {
            toggle(id) {
                this.expanded[id] = !this.expanded[id];
            },
        },
    };
</script>
