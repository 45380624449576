<template>
    <section>
        <slot :expanded="expanded" :toggle="toggle" />
    </section>
</template>

<script>
    export default {
        props: {
            initialItem: {
                type: Number,
                default: null,
            },
        },

        data() {
            return {
                expanded: this.initialItem,
            };
        },

        methods: {
            toggle(id) {
                this.expanded = id;
            },
        },
    };
</script>
