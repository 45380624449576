<template>
    <component :is="tag">0</component>
</template>

<script>
    const DURATION = 1000;
    const NUMBER_FORMAT = /\B(?=(\d{3})+(?!\d))/g;

    export default {
        props: {
            tag: {
                type: String,
                default: 'div',
            },
            to: {
                type: Number,
                required: true,
            },
        },

        data() {
            return {
                ticked: false,
                start: null,
            };
        },

        mounted() {
            window.addEventListener('scroll', this.onScroll);

            this.$nextTick(() => {
                this.onScroll();
            });
        },

        beforeUnmount() {
            clearInterval(this.interval);
            window.removeEventListener('scroll', this.onScroll);
        },

        methods: {
            onScroll() {
                if (this.ticked || !this.isElementVisible()) {
                    return;
                }

                this.ticked = true;
                this.start = (new Date()).getTime();
                this.tick();
            },
            tick() {
                const end = this.start + DURATION;
                const now = (new Date()).getTime();

                if (now < end) {
                    const perc = this.inOutQuad(1 - ((end - now) / DURATION));
                    const display = Math.min(this.to, Math.ceil(this.to * perc));
                    this.$el.innerHTML = display.toString().replace(NUMBER_FORMAT, ',');
                    requestAnimationFrame(this.tick);
                } else {
                    this.$el.innerHTML = this.to.toString().replace(NUMBER_FORMAT, ',');
                }
            },
            inOutQuad(n) {
                n *= 2;
                if (n < 1) { return 0.5 * n * n; }
                return - 0.5 * (--n * (n - 2) - 1);
            },
            isElementVisible() {
                const rect = this.$el.getBoundingClientRect();

                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
            },
        },
    };
</script>
